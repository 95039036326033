import React from 'react'
import "./Experience.css"
import {BsFillPatchCheckFill} from "react-icons/bs"


export default function Experience() {
  return (
    <section id='experience'>
      <h5>What Skills i Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon' />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon' />
              <div>
                <h4>Bootstrap</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon' />
              <div>
                <h4>jQuery</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon' />
              <div>
                <h4>React</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
        <h3>Backend Development</h3>
          <div className="experience__content">
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>Node JS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>PostgreSQL</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon' />
              <div>
                <h4>MongoDB</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>Python/Flask</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>AWS</h4>
                <small className='text-light'>Learning Progress</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>ASP.NET</h4>
                <small className='text-light'>Learning Progress</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
         <h3>IT Administrator</h3>
          <div className="experience__content">
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>Windows Server</h4>
                <small className='text-light'>8+ Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>Active Directory</h4>
                <small className='text-light'>8+ Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon' />
              <div>
                <h4>DNS & DHCP Server</h4>
                <small className='text-light'>8+ Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>VMWare Virtulization</h4>
                <small className='text-light'>5+ Experienced</small>
              </div>
            </article>
            <article className='experience__detailes'>
              <BsFillPatchCheckFill className='experience__detailes-icon'/>
              <div>
                <h4>Hyper-V</h4>
                <small className='text-light'>5+ Experienced</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}
