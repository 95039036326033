import React from 'react'
import "./Footer.css"
import {IoLogoTwitter} from 'react-icons/io'
import {TfiLinkedin} from "react-icons/tfi"
import { BsGithub } from 'react-icons/bs'

export default function Footer() {
  return (
    <footer>
      <a href="#" className='footer__logo'>RODELLA</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        {/* <li><a href="#services">Services</a></li> */}
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/ruby-hoseinzadeh/" target="_blank"><TfiLinkedin /></a>
        <a href="https://github.com/Robina78" target="_blank"><BsGithub /></a>
        <a href="https://twitter.com/Robina15098032" target="_blank"><IoLogoTwitter /></a>
      </div>
      <div className="footer__copyright">
        <small>&copy; 2022<a href='mailto:robina.sre78@gmail.com' target="_blank"> RODELLA</a> Tutorials.All rights reserved.</small>
      </div>
    </footer>
  )
}
